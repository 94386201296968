import React from "react";
import BeanImage from "../../../assets/images/backgrounds/ploverBeans.jpg";
import PloverStory from "../../../assets/images/backgrounds/ploverMillsStoryBackground.jpg";
import PloverSoybeans from '../../../assets/images/backgrounds/ploverSoybeans.jpg';
import PloverField from '../../../assets/images/backgrounds/ploverField.jpg';
import PloverTofu from '../../../assets/images/backgrounds/ploverTofu.png';
import Config from "../../config";

function Products() {
	return (
		<div
			className="w-full pt-40 pb-16 px-16 flex flex-col justify-between"
			style={{ backgroundColor: Config.styles.backgroundColor }}
		>
			<ProductSection
				img={BeanImage}
				title="Prograin Zeta"
				bulletPoints={[
					"$4.00 premium",
					"2875 chu",
					"SCN Resistant",
					"Semi bushy",
					"Medium tall plant",
					"Excellent yield potential",
				]}
			/>
			<ProductSection
				img={PloverStory}
				title="Prograin Wilma"
				bulletPoints={[
					"$4.50 premium",
					"2750 chu",
					"Top performer at OSACC trials",
					"Performs well in all row widths",
					"Phytophthora protection",
				]}
			/>
			<ProductSection
				img={PloverSoybeans}
				title="Silverline S07-M8"
				bulletPoints={[
					"$4.50 premium",
					"2725 chu",
					"White mould tolerant",
					"Tall plant with good standability",
					"Excellent yield rating",
				]}
			/>
			<ProductSection
				img={PloverTofu}
				title="Silverline S04-K9"
				bulletPoints={[
					"$4.50 premium",
					"2650 chu",
					"SCN resistant",
					"Excellent Emergence",
					"Adaptable row width and soil type",
				]}
			/>
			<ProductSection
				img={PloverField}
				title="Black Beard"
				bulletPoints={[
					"Premium TBD",
					"100 Day",
					"Strong yielding bean",
					"Moderate white mould tolerance",
				]}
			/>
			<div className="w-full flex text-center items-center justify-center">
				<div className="flex flex-col font-semibold max-w-[900px]">
					<p>IP bean on-farm storage $0.50/bushel bonus FOB</p>
					<p>IP and Edible beans cannot follow GMO beans in the same field on consecutive years</p>
					<p>Maximum 15% moisture tolerance on IP beans</p>
					<p><strong>Early Bird Promotion</strong></p>
					<p>Book 2025 IP and Edible acres by <strong>December 1, 2024</strong> to qualify for early bird draw. Grower
						agreements must be signed and returned to be eligible for the draw. <strong>Winning draw prize: 30
							units of Prograin Seed or $2000 travel voucher.</strong></p>
					<p>CPlover Mills Commodities is a certified dealer of Prograin and Blackbean seeds</p>
				</div>
			</div>
		</div>
	);
}

function ProductSection(props) {
	return (
		<div className="flex flex-col-reverse tablet:flex-row py-4">
			<img
				className="rounded-lg shadow-md h-80  tablet:w-80 object-cover"
				src={props.img}
				alt={props.title}
			></img>
			<div className="pb-10 tablet:pb-0 tablet:pl-10">
				<h2
					className="text-4xl font-bold"
					style={{ color: Config.styles.primaryColor }}
				>
					{props.title}
				</h2>

				<p className="max-w-2xl text-xl pt-4">{props.description}</p>
				{typeof props.bulletPoints !== "undefined" && (
					<ul className="pl-10 pt-2">
						{props.bulletPoints.map((points) => (
							<li key={points} className="list-disc text-gray-600">
								{points}
							</li>
						))}
					</ul>
				)}
			</div>

			{typeof props.trailingImage !== "undefined" && (
				<img
					className="pb-10 tablet:pb-0 tablet:pl-10"
					src={props.trailingImage}
					alt="test"
				></img>
			)}
		</div>
	);
}
export default Products;
